export enum Constants {
  DROP_SET_TYPE = 'DROP_SET_TYPE',
  DROP_SET_STEP = 'DROP_SET_STEP',
  DROP_SET_LOADING = 'DROP_SET_LOADING',
  DROP_SET_CHAIN_ID = 'DROP_SET_CHAIN_ID',
  DROP_SET_TOKEN_ADDRESS = 'DROP_SET_TOKEN_ADDRESS',
  DROP_SET_AMOUNT = 'DROP_SET_AMOUNT',
  DROP_SET_TOKEN_ID = 'DROP_SET_TOKEN_ID',
  DROP_SET_TITLE = 'DROP_SET_TITLE',
  DROP_SET_AUTOCLAIM = 'DROP_SET_AUTOCLAIM',
  DROP_SET_CAMPAIGN_ID = 'DROP_SET_CAMPAIGN_ID',
  DROP_SET_LINKDROP_SIGNER_SIGNATURE = 'DROP_SET_LINKDROP_SIGNER_SIGNATURE',
  DROP_SET_LINKDROP_MASTER_ADDRESS = 'DROP_SET_LINKDROP_MASTER_ADDRESS',
  DROP_SET_HASH = 'DROP_SET_HASH',
  DROP_SET_LINK_KEY = 'DROP_SET_LINK_KEY',
  DROP_SET_EXPIRATION_TIME = 'DROP_SET_EXPIRATION_TIME',
  DROP_SET_WALLET = 'DROP_SET_WALLET',
  DROP_SET_IS_MANUAL = 'DROP_SET_IS_MANUAL',
  DROP_SET_WEI_AMOUNT = 'DROP_SET_WEI_AMOUNT',
  DROP_SET_IS_CLAIMED = 'DROP_SET_IS_CLAIMED',
  DROP_SET_THEME = 'DROP_SET_THEME',
  DROP_SET_ERROR = 'DROP_SET_ERROR',
  DROP_SET_ADDRESS_MANUALLY_SET = 'DROP_SET_ADDRESS_MANUALLY_SET',
  DROP_SET_CLAIM_CODE = 'DROP_SET_CLAIM_CODE',
  DROP_SET_LINK_ID = 'DROP_SET_LINK_ID',
  DROP_SET_CLAIMING_FINISHED_DESCRIPTION = 'DROP_SET_CLAIMING_FINISHED_DESCRIPTION',
  DROP_SET_CLAIMING_FINISHED_BUTTON_TITLE = 'DROP_SET_CLAIMING_FINISHED_BUTTON_TITLE',
  DROP_SET_CLAIMING_FINISHED_BUTTON_URL = 'DROP_SET_CLAIMING_FINISHED_BUTTON_URL',
  DROP_SET_WALLET_APP = 'DROP_SET_WALLET_APP',
  DROP_SET_AVAILABLE_WALLETS = 'DROP_SET_AVAILABLE_WALLETS',
  DROP_SET_MULTISCAN_STEP = 'DROP_SET_MULTISCAN_STEP',
  DROP_SET_PREVIEW_SETTING = 'DROP_SET_PREVIEW_SETTING',

  DROP_SET_MULTISCAN_WHITELIST_ON = 'DROP_SET_MULTISCAN_WHITELIST_ON',
  DROP_SET_MULTISCAN_WHITELIST_TYPE = 'DROP_SET_MULTISCAN_WHITELIST_TYPE',
  DROP_SET_MULTISCAN_LINK_DECRYPTED = 'DROP_SET_MULTISCAN_LINK_DECRYPTED'
}